<template>
    <div class="container">
        <div class="container_block">

            <div class="container_block_itemwraper" v-for="item in list" :key="item">
                <div class="container_block_item">
                <div class="container_block_item_check">
                    <el-checkbox v-model="item.check"  size="large" :name="item.id"/>
                </div>
                <div class="container_block_item_pic">
                    <img :src="item?.vlShopGoods?.images[0]" alt="">
                </div>
                <div class="container_block_item_info">
                    <div class="container_block_item_info_title">
                        {{item.vlShopGoods.name}}
                    </div>
                     <template v-if="item.vlShopGoods.payType==='1'">
                        <div class="container_block_item_info_price">
                            <span class="container_block_item_info_price_tag">&yen;{{item.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}} </span> <span class="container_block_item_info_price_unit">/{{item.vlShopGoods?.vlUnit?.unit}}</span>
                        </div>
                        <div class="container_block_item_info_score" v-show="false">
                            积分：- {{item.vlShopGoods.score}}
                        </div>
                    </template>
                     <template v-if="item.vlShopGoods.payType==='2'">
                        <div class="container_block_item_info_price" v-show="false">
                            <span class="container_block_item_info_price_tag">1.79</span> <span class="container_block_item_info_price_unit">/{{item.vlShopGoods?.vlUnit?.unit}}</span>
                        </div>
                        <div class="container_block_item_info_score">
                            积分：- {{item.vlShopGoods.score}}
                        </div>
                    </template>
                    <template v-if="item.vlShopGoods.payType==='3'">
                        <div class="container_block_item_info_price">
                            <span class="container_block_item_info_price_tag">&yen;{{item.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}} </span> <span class="container_block_item_info_price_unit">/{{item.vlShopGoods?.vlUnit?.unit}}</span>
                        </div>
                        <div class="container_block_item_info_score">
                            积分：- {{item.vlShopGoods.score}}
                        </div>
                    </template>
                </div>

                <div>
                    <el-input-number v-model="item.num" :min="1" :max="9999" @change="onValueChange(item)" />
                </div>

            </div>
            <el-divider></el-divider>
        </div>


            <div class="container_block_btns">
                <div class="container_block_btns_check"><el-checkbox v-model="checked"  size="large" label="全选"  @change="checkAll"/><span class="container_block_btns_choosed">已选择<span style="color:red">{{countNum}}</span>件商品</span> <span class="container_block_btns_remove"><el-button @click="del">删除选择的商品</el-button></span></div>
                <div class="container_block_btns_total">
                    <span class="container_block_btns_total_title">合计：</span><span class="container_block_btns_total_price">￥{{totalPrice}}<span v-if="totalScore>0">+积分{{totalScore}}</span></span> <span> <el-button type="warning" @click="toPay">结算</el-button></span>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup name="ShopCart">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { getCart, updateCart,deleteShopCartByIds } from '../../api/shop';
import { watchEffect } from '@vue/runtime-core';
import {ElMessage, ElMessageBox} from "element-plus";
const router = useRouter()
const list = ref([]);
const payList = ref([]);
const ids = ref([]);
const loading = ref(false);
const refreshing = ref(false);
const finished = ref(false);
const checked = ref(false);
const totalPrice = ref(0);
const totalScore = ref(0);
const countNum = ref(0);
const onLoad = async () => {
  loading.value = true;
  let result = await getCart();
  console.log(result)
  if (result != null && result.code === 200) {
    loading.value = false;
    list.value = result.rows;
    refreshing.value = false;
    finished.value = true;
  }else {
    list.value = [];
  }
}
onLoad()
const checkAll = () => {
  list.value.map(item => item.check = checked.value)
  if(checked.value === true){
    countNum.value = list.value.length;
  }else {
    countNum.value = 0;
  }

}

const toPay = () => {
    for(let item of list.value){
        if (item!=null && item.check === true){
            payList.value.push(item)
        }
    }
    if(payList.value.length === 0){
      ElMessage.error("请选择一种商品")
      return;
    }
  router.push({ name: "ShopPay", params: { list: JSON.stringify(payList.value),totalPrice: totalPrice.value,totalScore: totalScore.value } })
}

const del = async()=>{
  for (let item of list.value) {
    if (item != null && item.check === true) {
      ids.value.push(item.id)
    }
  }
  if(ids.value.length === 0){
    ElMessage.error("请选择一种商品")
    return;
  }
  ElMessageBox.confirm('确定要删除此商品吗?')
      .then(async () => {
        let result = await deleteShopCartByIds(ids.value);
        if (result != null && result.code === 200) {
          onLoad();
          ids.value = [];
        }
      })
      .catch(() => {
        // catch error
      })
}

watchEffect(() => {
  let allcheck = true
  let price = 0;
  let score = 0;
  let count = 0;
  for (let item of list.value) {
    console.log("item.check==", item)
    if (!item.check) {
      allcheck = false
    } else {
      if(item.vlShopGoods.payType==="1"){
        price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
        count += item.num;
      }else if(item.vlShopGoods.payType==="2"){
        score += item.vlShopGoods.score * item.num
        count += item.num;
      } else{
        price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
        score += item.vlShopGoods.score * item.num
        count += item.num;
      }

    }
  }
  checked.value = allcheck
  totalPrice.value = price;
  totalScore.value = score;
  countNum.value = count;
})

const onValueChange = async (item) => {
  console.log("item==", item)
  let result = await updateCart(item);
  if (result.code === 200) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

}


</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: calc(100vh - 60px) !important;
  background-color: #f5f5f5;
  padding-top: 30px;
  overflow-y: scroll;
  &_block {
    margin: 0 auto;
    padding: 30px 30px 30px 30px;
    width: 65%;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_itemwraper{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &_item{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        &_check{
            margin-right: 20px;
        }
        &_pic{
            margin-right: 30px;
        }
        &_pic img{
            height: 150px;
        }
        &_info{
            flex: 1;
            height: 150px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &_title{
                font-size: 20px;
                font-weight: bold;
                color: #212121;
            }
            &_price{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 20px;
                &_tag{
                    color: #FF6200;
                    font-size: 28px;
                    margin-right: 5px;
                }
                &_unit{
                    color: #000000;
                    font-size: 16px;
                }

            }
             &_score{
                margin-top: 30px;
                border-radius: 2px;
                background-color: rgba(255, 255, 255, 100);
                text-align: center;
                border: 1px solid rgba(255, 145, 0, 100);
                padding: 10px 10px;
                color: #FF9100;
                font-size: 14px;
                }
        }
    }
    &_btns{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &_check{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        &_choosed{
            margin-top: 2px;
            margin-left: 140px;
            color: #BDBDBD;
            font-size: 18px;
        }
        &_remove{
          button{
            margin-top: 5px;
            margin-left:50px;
            border:none;
            color: red;
            outline:none;
            font-size: 16px;
          }
          button:hover{
            background:none;
          }
          button:focus{
            outline:none;
            background:none;
          }
        }

        &_total{
            display: flex;
            flex-direction: row;
            align-items: center;
            &_title{
                color: #212121;
                font-size: 20px;
            }
            &_price{
                color: #FF9100;
                font-size: 28px;
                margin-right: 60px;
            }
        }

    }
  }
}
:deep(.el-button--warning){
    width: 240px;
    height: 60px;
    color: #3B2F00;
    background-color: #FFCF1A;
    border: 1px solid #FFCF1A;
    font-size: 20px;
}

:deep(.el-checkbox__inner) { //大小
      width: 32px;
      height: 32px;
      border-color: #384461;
      border-radius: 30px;
    }
:deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) { //对钩的位置
    top: 1px;
    left: 4px;
}
:deep(.el-input-number__increase){
    border-radius: 20px;
}

:deep(.el-input-number__increase.is-disabled){
     border-radius: 20px;

}
:deep(.el-input-number__decrease){
     border-radius: 20px;
}
:deep(.el-input-number__decrease.is-disabled){
     border-radius: 20px;
}
:deep(.el-input__wrapper){
    border-radius: 30px;
}

</style>
